
.quality-course-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    .student-main-body {
        flex: 1;
        height: 1%;
        background-color: #F2F2F2;
        overflow: auto;
        /*display: flex;*/
        /*flex: 1;*/
        /*justify-content: center;*/
        /*align-items: flex-start;*/
        /*height: calc(100% - 60px);*/
        /*background-color: #F2F2F2;*/
        /*box-sizing: border-box;*/
        /*overflow: auto;*/
    }
}


.temp-popover {
    display: flex;
    flex-direction: column;

    .popover-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 30px;

        &:hover {
            background: #ECF5FF;

            span {
                color: #409EFF;
            }
        }

        span {
            color: #666666;
            font-size: 14px;
            padding-left: 10px;
        }

        i {
            color: #666666;
            font-size: 12px;
            padding-right: 10px;
        }
    }
}

.featured-content {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;

    .featured-index {
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .featured-left {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            width: 1%;
            background-color: #F2F8FF;
            margin-right: 20px;

            .featured-item {
                &:nth-child(2) {
                    margin-bottom: 64px;
                }

                &:nth-child(3) {
                    margin-bottom: 30px;

                    .item-title {
                        display: flex;
                        justify-content: space-between;

                        .upload-material {
                            width: 70px;
                            height: 30px;
                            background: #ECF5FF;
                            border: 1px solid #DCECFE;
                            border-radius: 4px;
                            color: #409EFF;
                            font-size: 14px;
                            text-align: center;
                            line-height: 30px;

                            &:hover {
                                color: #409EFF;
                                border-color: #c6e2ff;
                                background-color: #ecf5ff;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .item-title {
                    position: relative;
                    font-size: 16px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 4px;
                        width: 4px;
                        height: 16px;
                        background-color: #409EFF;
                    }

                    .text {
                        margin-left: 15px;
                    }
                }

                .featured-list {
                    width: 100%;
                    height: 120px;
                    background-color: #F5F7FA;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;

                    .dragg-box {
                        width: 100%;
                        display: flex;
                    }

                    .featured-list-item {
                        display: flex;
                        margin-right: 30px;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: calc((100% - 75px) / 7);
                        /*min-width: 140px;*/
                        margin-right: 37px;
                        height: 120px;
                        background: #ffffff;
                        border-radius: 6px;

                        .inner-box {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        &:hover {
                            box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        }

                        .item-cover {
                            width: 50px;
                            height: 50px;
                            line-height: 40px;
                            text-align: center;
                            border-radius: 50%;
                            cursor: pointer;

                            i {
                                font-size: 20px;
                                color: #fff;
                            }

                            img {
                                width: 50px;
                                height: 50px;
                            }

                            .app-icon {
                                max-width: 100%;
                                max-height: 100%;
                                border-radius: 5px;
                            }
                        }

                        &:nth-of-type(7) {
                            margin-right: 0;
                        }

                        .circle-box {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #ffffff;

                            ::v-deep .iconfont {
                                font-size: 20px;
                            }
                        }

                        .item-name {
                            margin-top: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .module-content {
                display: flex;
                justify-content: space-between;
                padding: 19px 20px;
                /*position: absolute;*/
                /*bottom: 20px;*/
                /*left: 0;*/
                /*right: 0;*/
                align-items: center;
                box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
                position: relative;
                background: #FFFFFF;

                .module {
                    flex: 1;
                    width: 1%;

                    ::v-deep .el-scrollbar__wrap {
                        overflow-y: hidden;
                    }

                    ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                        white-space: nowrap;
                    }
                }

                .module-list {
                    display: flex;
                    position: relative;
                    padding: 6px;

                    .new-module-item {
                        position: relative;
                        width: 60px;
                        height: 60px;
                        border-radius: 10px;
                        margin-right: 15px;
                    }

                    .module-item {
                        position: relative;
                        width: 60px;
                        height: 60px;
                        border: 1px dashed #CCCCCC;
                        border-radius: 24px;
                        margin-right: 13px;
                    }

                    .item-cover {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 50%;

                        i {
                            font-size: 20px;
                            color: #fff;
                        }
                    }

                    .item-cover1 {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        border-radius: 50%;

                        i {
                            font-size: 20px;
                            color: #fff;
                        }

                        .show-img {
                            width: 100%;
                            height: 100%;
                            box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                            border-radius: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .img-box {
                                width: 50px;
                                height: 50px;
                                border-radius: 24px;
                            }
                        }
                    }

                    .show1 {
                        background: #61BCEB;
                    }

                    .show2 {
                        background: #3DCAC8;
                    }

                    .show3 {
                        background: #F0BC49;
                    }

                    .show4 {
                        background: #EC75A4;
                    }

                    .show5 {
                        background: #79ACDC;
                    }

                    .show6 {
                        background: #61BCEB;
                    }

                    .show7 {
                        background: #5AB9E9;
                    }

                    .courseware {
                        background: linear-gradient(0deg, #F5D971, #EDA92E);
                    }

                    .textbook {
                        background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                    }

                    .lesson-plan {
                        background: linear-gradient(0deg, #53DED1, #2BB9BF);
                    }

                    .micro-lesson {
                        background: linear-gradient(0deg, #EE89B5, #EA5D90);
                    }

                    .animation {
                        background: linear-gradient(0deg, #F39E72, #EC6C6C);
                    }

                    .test-center {
                        background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                    }

                    .training-center {
                        background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                    }

                    .knowledge-points {
                        background: linear-gradient(0deg, #77C9F1, #47AEE4);
                    }

                    .material-library {
                        background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                    }

                    .case-library {
                        background: linear-gradient(0deg, #F5D971, #EDA92E);
                    }

                    .related-copywriting {
                        background: linear-gradient(0deg, #8BC3EB, #628EC9);
                    }

                    .personal-info {
                        background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                    }

                    .del-btn {
                        width: 18px;
                        height: 18px;
                        background: #FF0000;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        opacity: 0;
                        cursor: pointer;
                        z-index: 1;
                    }

                    .show-title {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        z-index: 1;
                    }

                    .new-module-item:before {
                        content: "";
                        width: 0;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        opacity: 0;
                    }


                    .new-module-item:hover:before {
                        width: 100%;
                        left: 0;
                        opacity: 0.5;
                    }

                    .new-module-item:hover .del-btn {
                        opacity: 1;
                    }
                }

                .module-save-btn {
                    width: 60px;
                    height: 60px;
                    background: #409EFF;
                    border-radius: 10px;
                    color: #FFFFFF;
                    font-size: 14px;
                    //line-height: 60px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    font-weight: 400;
                    margin-right: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .adhibition-btn {
                    width: 60px;
                    height: 60px;
                    background: #409EFF;
                    border-radius: 10px;
                    color: #FFFFFF;
                    font-size: 14px;
                    line-height: 60px;
                    text-align: center;
                    font-weight: 400;
                    cursor: pointer;
                }
            }

            .featured-topbox {
                z-index: 1;
                margin-top: 16px;
                display: flex;
                align-items: center;
                padding: 30px 30px 30px 0;
                position: relative;
                background: #FFFFFF;
                box-shadow: 0px 5px 10px 1px rgba(32, 32, 32, 0.05);

                span {
                    display: inline-block;
                    line-height: 1;
                    font-size: 14px;
                    padding: 8px 12px;
                    border-radius: 4px;
                    font-weight: 400;
                    cursor: pointer;
                }

                .class-item {
                    background: #409EFF;
                    box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                    border-radius: 20px;
                    color: #ffffff;
                }
            }

            .featured-top {
                flex: 1;
                height: 1%;
                background: #ffffff;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                    margin-right: -37px !important;
                }

                ::v-deep .el-scrollbar__view {
                    margin-right: 20px;
                }

                .featured-mainbox {
                    width: 100%;
                    height: 100%;

                    .mainbox-list {
                        padding: 30px 60px;
                        min-height: 520px;

                        .dragg-box {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                        }

                        .mainbox-list-item {
                            cursor: pointer;
                            width: 50%;
                            margin-bottom: 50px;
                            display: flex;

                            .item-title {
                                margin-left: 30px;
                                display: flex;
                                align-items: center;
                            }
                        }

                        .no-data {
                            width: 100%;
                            font-size: 16px;
                            text-align: center;
                        }
                    }

                    .page-box {
                        text-align: center;
                    }
                }
            }
        }

        .featured-right {
            width: 230px;
            min-width: 189px;
            background-color: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .item-title {
                position: relative;
                font-size: 16px;
                padding: 20px 20px 0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 27px;
                    width: 6px;
                    height: 10px;
                    background: #409EFF;
                    border-radius: 3px;
                }

                .text {
                    margin-left: 15px;
                }
            }

            .app-list {
                padding: 0 20px;
                display: flex;
                flex-wrap: wrap;

                .app-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 16px 20px;
                    cursor: pointer;
                    border-radius: 10px;

                    &:hover {
                        transform: scale(1.2);
                        transition: 1s;
                        box-shadow: 0px 4px 8px 1px rgba(9, 95, 185, 0.17);
                        border-radius: 6px;
                    }

                    .app-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 60px;
                        height: 60px;
                    }

                    img.app-icon {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 5px;
                    }

                    span.app-name {
                        color: #333;
                        font-size: 12px;
                        margin-top: 10px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 160px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.upload-resources-dialog {
    .info-form {
        .file {
            ::v-deep .el-form-item__content {
                position: relative;
                display: flex;
                flex-direction: column;
            }

            .file-name {
                display: flex;
                position: relative;
            }

            .upload-cover {
                width: 100px;
                height: 30px;
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                border-radius: 5px;
                color: #409EFF;
                line-height: 30px;
                text-align: center;
                margin-right: 15px;

                i.iconfont {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }

            .cover-input {
                position: absolute;
                top: 0;
                width: 100px;
                height: 30px;
                opacity: 0;
                cursor: pointer;
            }
        }

        .resources-class-cascader {
            width: 100%;
        }

        .select-course {
            width: 100%;
        }
    }
}

.app-detail-dialog {
    ::v-deep .el-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #e6e7ea;
        }

        .title {
            text-align: center;
            margin: 0;
        }

        .content {
            display: flex;
            flex-direction: column;

            .h2 {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin: 10px 0;
            }

            .soft-introduction {
                text-align: center;
            }

            .soft-content {
                padding: 10px 50px;
                min-height: 200px;
            }
        }

        ::v-deep .el-dialog__footer {
            border-top: 1px solid #e6e7ea;
            padding: 20px;

            a {
                padding: 7px 20px;
                font-size: 14px;
                border-radius: 4px;
                margin-right: 20px;
            }

            a.home {
                border: 1px solid #DCDFE6;
                color: #606266;

                &:hover {
                    color: #409EFF;
                    border-color: #c6e2ff;
                    background-color: #ecf5ff;
                }
            }

            a.shop {
                color: #FFF;
                background-color: #409EFF;
                border-color: #409EFF;

                &:hover {
                    background: #66b1ff;
                    border-color: #66b1ff;
                    color: #FFF;
                }
            }
        }
    }
}


.header-wrapper {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #dcdfe6;
    .header {
        position: relative;
        width: 1200px;
        height: 60px;
        line-height: 60px;
        margin: 0 auto;
        display: flex;
        .nav {
            flex: 1;
            width: 1%;
            border-bottom: none;
            .el-menu-item {
                padding: 0;
                .nav-item {
                    display: block;
                    padding: 0 20px;
                }
            }
        }
        .logo {
            /*width: 200px;*/
            font-size: 18px;
            font-weight: 700;
            margin-right: 20px;
            overflow: hidden;
        }
        .user {
            text-align: right;
            margin-left: 20px;
            span {
                font-size: 16px;
                margin-right: 20px;
            }
        }
    }
}
.quality-course-container {
    .header-wrapper {
        display: block;
        z-index: 1001;
        height: 60px;
        width: 100%;
        box-shadow: 0 0 5px rgba(0,0,0,0.1), 0 0 5px rgba(0,0,0,0.1);
        background: #fff;
        /*min-width: 1000px;*/
        border-bottom: none;
        .header {
            width: 100%;
            padding-right: 70px;
            padding-left: 70px;
            box-sizing: border-box;
            .logo {
                width: 200px;
                height: 60px;
                cursor: pointer;
                margin: 0;
                /*height: 100%;*/
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 8px;
                img {
                    /*width: 130px;
                    max-width: 100%;*/
                    /*height: auto;*/
                    /*width: 100%;*/
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .user {
                display: flex;
                .m-userinfo {
                    a {
                        display: block;
                        font-size: 16px;
                        color: #666;
                    }
                }
            }
            .user .header-el-dropdown{
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 0;
                .user-text {
                    line-height: 40px;
                    margin-left: 10px;
                }
                img {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
        }
    }
    .el-dropdown-menu {
        ::v-deep li {
            padding: 0 25px 0 10px;
            height: 27px;
            line-height: 27px;
            font-size: 14px;
            color: #666;
            &:hover {
                color: #4A90E2;
                background-color: transparent;
            }
            i {
                font-size: 16px;
                color: #666;
            }
        }
    }
}
.user-info {
    display: flex;
    align-items: center;
    .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .el-dropdown {
        .user-text {
            display: flex;
            align-items: center;
            &:hover {
                color: #409EFF;
            }
            .user-name {
                max-width: 80px;
                display: block;
                cursor: pointer;
                &:focus {
                    outline: none;
                }
            }
        }
        padding-left: 20px;
    }
}
@media (min-width: 576px) {
    .quality-course-container .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .quality-course-container .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .quality-course-container .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .quality-course-container .container {
        max-width: 1192px;
    }
}

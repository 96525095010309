
::v-deep .el-dialog {
    width: 1800px;
}

::v-deep .el-dialog__body {
    width: calc(100% - 120px);
    min-width: 1000px;
    text-align: center;
}

::v-deep .el-dialog__header::after {
    content: '';
    width: 100%;
    height: 10px;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #EEEEEE;
}

.main-box {
    height: 680px;
    margin-top: 28px;
}

::v-deep .el-dialog__footer {
    text-align: center;
}


body, html {
    margin: 0;
    padding: 0;
    //Microsoft Yahei,
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //-webkit-tap-highlight-color: transparent;
    height: 100%;
    color: #333;
    background: #f4f5f5;
    font-size: 14px;
}
a {
    color: #1989fa;
    text-decoration: none;
}
a:focus, a:hover {
    color: #409eff;
}
.color-blue {
    cursor: pointer;
}
.el-button .iconfont {
    vertical-align: baseline;
}
.clearfix {
    zoom: 1;
}
.clearfix:after {
    content: '';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}
.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.text-overflow-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.index-container {
    position: relative;
    width: 1200px;
    margin: 20px auto;
    background: #fff;
    height: calc(100% - 100px);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
}
.index-tabs {
    background: #fff;
    padding: 10px 20px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    .el-tabs__content {
        flex: 1;
        height: 1%;
        .el-tab-pane {
            height: 100%;
            .resources-content {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
.class-manage-resources {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.pages-right {
    text-align: right;
    margin-top: 20px;
    padding: 0;
}
.pages-center {
    text-align: center;
    margin-top: 20px;
    padding: 0;
}

/*start 精品课程*/
.quality-course {
    display: flex;
    height: 100%;
    .course-left {
        width: 380px;
        height: 100%;
        background: #FEFDFC;
        border-right: 1px solid #E2E2E2;
        display: flex;
        flex-direction: column;
        transition: display .6s ease;
        .course-logo {
            color: #404040;
            font-size: 22px;
            font-weight: 700;
            height: 65px;
            line-height: 65px;
            border-bottom: 1px solid #E2E2E2;
            padding-left: 40px;
            padding-right: 20px;
            display: flex;
            justify-content: space-between;
            .dis-course {
                font-size: 18px;
                color: #999;
            }
        }
    }
    .course-right {
        width: 1%;
        flex: 1;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .right-header {
            height: 65px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            justify-content: space-between;
            border-bottom: 1px solid #E2E2E2;
            .header-left {
                width: 1%;
                flex: 1;
                display: flex;
                align-items: center;
                .icon-screen {
                    font-size: 20px;
                    margin-right: 25px;
                    cursor: pointer;
                    transition: all .6s ease;
                    line-height: 1.5;
                    &.current {
                        transform: rotate(180deg);
                    }
                }
                .course-title {
                    font-size: 18px;
                    font-weight: 700;
                    color: #404040;
                    transition: all .3s;
                    max-width: 500px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                    &:hover {
                        color: #fa8919;
                    }
                }
                .see-detail-btn {
                    color: #fff;
                    width: 116px;
                    height: 33px;
                    line-height: 33px;
                    background: #fa8919;
                    margin-left: 18px;
                    border-radius: 4px;
                    text-align: center;
                }
            }
            .user-icon {
                display: block;
                font-size: 20px;
                color: #1C1F21;
                padding: 0 4px;
                line-height: 28px;
            }
        }
        .right-content-wrapper {
            height: 1%;
            flex: 1;
            position: relative;
            .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    padding-bottom: 50px;
                }
            }
            .right-content {
                width: 850px;
                margin: 0 auto;
                position: relative;
                &.detail-content {
                    width: 680px;
                }
            }
        }
    }
}
.el-popper.user-info {
    padding: 0;
    margin-top: 0;
    .user-info-popover {
        .info-top {
            font-size: 13px;
            color: #404040;
            height: 44px;
            border-bottom: 1px solid #e9e9e9;
            padding: 10px 0 0 16px;
            .school {
                font-size: 12px;
                color: #b2b2b2;
            }
        }
        .info-list {
            padding: 10px 0;
            .info-item {
                display: block;
                height: 43px;
                line-height: 43px;
                color: #b2b2b2;
                padding-left: 16px;
                .iconfont {
                    font-size: 16px;
                    padding: 0 5px;
                }
                &:hover {
                    background: #f6f7fb;
                }
            }
        }
        .info-bottom {
            color: #b2b2b2;
            display: block;
            height: 43px;
            line-height: 43px;
            border-top: 1px solid #e9e9e9;
            padding-left: 16px;
            &:hover {
                background: #f6f7fb;
            }
        }
    }
}
.btn-orange {
    color: #fff;
    background: #fa8919;
    border-color: #fa8919;
}
.btn-orange:focus,
.btn-orange:hover {
    color: #fff;
    background: #ff7800;
    border-color: #ff7800;
}
.el-button.btn-orange.is-plain {
    color: #FA8919;
    background: #fff;
    border-color: #FA8919;
}
.el-button.btn-orange.is-plain:focus,
.el-button.btn-orange.is-plain:hover {
    color: #fff;
    background: #FA8919;
    border-color: #FA8919;
}
.btn-green {
    color: #fff;
    background: #00b43c;
    border-color: #00b43c;
}
.btn-green:focus,
.btn-green:hover {
    color: #fff;
    background: #00c850;
    border-color: #00c850;
}
.orange-tab-e {
    .el-tabs__item {
        height: 50px;
        line-height: 50px;
        padding: 0 32px;
        font-size: 20px;
        color: #404040;
    }
    .el-tabs__item.is-active {
        color: #FA8919;
        font-weight: 500;
    }
    .el-tabs__active-bar {
        background-color: #FA8919;
    }
    .el-tabs__item:hover {
        color: #FA8919;
    }
    .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #e9e9e9;
    }
}

/*end 精品课程*/

::v-deep .el-dialog {
    width: calc(100% - 120px);
    min-width: 1000px;
}

::v-deep .el-dialog__header::after {
    content: '';
    width: 100%;
    height: 10px;
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid #EEEEEE;
}

::v-deep .el-dialog__footer {
    text-align: center;
}

::v-deep .el-dialog__body {
    position: relative;
}

.featured-content {
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    ::v-deep .el-dialog {
        margin-top: 10vh !important;
    }

    .course-index {
        width: 100%;
        height: 100%;
        /*min-width: 1460px;*/
        background-size: 100% 100%;
        background: linear-gradient(184deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0));
        box-shadow: 0px 9px 9px 1px rgba(9, 95, 185, 0.1);
        display: flex;
        flex-direction: column;
        flex: 1;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .top-box {
            width: 100%;
            height: 50%;
            min-height: 440px;
            background: linear-gradient(153deg, #ECF6FF, #F0F3FF);
            display: flex;
            /*flex: 1;*/

            .top-leftBox {
                width: 586px;
                height: 330px;
                padding: 60px 30px 32px 60px;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .top-rightBox {
                height: 330px;
                padding: 60px 60px 32px 0;

                .rightBox-course {
                    display: flex;
                    margin-top: 30px;

                    .course-title-box1 {
                        font-size: 24px;
                        font-weight: 400;
                        color: #313F54;
                    }

                    .course-title-box2 {
                        width: 110px;
                        background-image: url("../../assets/images/courseIndex.png");
                        margin-left: 30px;
                        text-align: center;
                        color: #FFFFFF;
                        height: 30px;

                        i {
                            margin-right: 8px;
                            font-size: 14px;
                        }
                    }

                    .course-title-midBox {
                        font-size: 16px;
                        color: #313F54;
                        max-width: 700px;
                        max-height: 150px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 7;

                        span {
                            font-size: 14px;
                            word-break: break-all;
                            line-height: 1.5;
                            max-height: 120px;
                        }
                    }
                }
            }
        }

        .bottom-box {
            width: 100%;
            height: 50%;
            flex: 1;
            min-height: 440px;
            background: #F2F8FF;

            .course-list {
                display: flex;
                flex-wrap: wrap;
                padding: 29px 65px 65px;

                .list-item {
                    cursor: pointer;
                    width: calc(25% - 30px);
                    height: 100px;
                    background: #FFFFFF;
                    box-shadow: 0px 5px 17px 3px rgba(9, 95, 185, 0.1);
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    margin-right: 28px;

                    &:nth-of-type(4n) {
                        margin-right: 0;
                    }

                    &:nth-of-type(4) {
                        margin-top: 0 !important;
                    }

                    img {
                        width: 58px;
                        height: 58px;
                        margin-left: 40px;
                    }

                    span {
                        margin-left: 20px;
                    }

                    &.row3 {
                        margin-top: 80px;

                    }

                    &.row4 {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}


.textbook-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .textbook-list {
        flex: 1;
        width: 100%;
        height: 1%;
        background-color: #fff;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .textbook {
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            .textbook-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 30px 16px 0;
                cursor: pointer;
                border-radius: 10px;
                &:hover {
                    transform: scale(1.2);
                    transition: 1s;
                }
                .textbook-cover {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 107px;
                    height: 107px;
                }
                img.textbook-icon {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 5px;
                }
                span.textbook-name {
                    color: #333;
                    font-size: 12px;
                    margin-top: 10px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 107px;
                    text-align: center;
                }
            }
        }
    }
    .module-content {
        display: flex;
        justify-content: space-between;
        padding: 19px 20px;
        align-items: center;
        box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
        background-color: #fff;
        .module {
            flex: 1;
            width: 1%;
            ::v-deep .el-scrollbar__wrap .el-scrollbar__view {
                white-space: nowrap;
            }
        }
        .module-list {
            display: flex;
            padding: 6px;
            .module-item {
                display: inline-block;
                min-width: 60px;
                min-height: 60px;
                border: 1px dashed #CCCCCC;
                border-radius: 10px;
                margin-right: 15px;
            }
        }
        .module-save-btn {
            width: 60px;
            height: 60px;
            background: #ECF5FF;
            border: 1px solid #DCECFE;
            border-radius: 4px;
            color: #409EFF;
            font-size: 14px;
            line-height: 60px;
            text-align: center;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}


.header-wrapper {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #dcdfe6;
    .header {
        position: relative;
        width: 1200px;
        height: 59px;
        line-height: 59px;
        margin: 0 auto;
        display: flex;
        .nav {
            flex: 1;
            width: 1%;
            border-bottom: none;
            .el-menu-item {
                padding: 0;
                .nav-item {
                    display: block;
                    padding: 0 20px;
                }
            }
        }
        .logo {
            /*width: 200px;*/
            font-size: 18px;
            font-weight: 700;
            margin-right: 20px;
            overflow: hidden;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .user {
            text-align: right;
            margin-left: 20px;
            span {
                font-size: 16px;
                margin-right: 20px;
            }
        }
    }
}
